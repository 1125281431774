<template>
	<div class="header-nav-bar">
		<div class="nav-bar-left">
			<a class="menu-link" @click.prevent="toggleMenu">
				<MenuIcon class="menu-icon"></MenuIcon>
			</a>
			<a v-if="back" @click="$router.go(-1)" class="back-link svg-hold">
				<BackIcon class="menu-icon"></BackIcon>
			</a>
		</div>
		<div class="nav-bar-right">
			<a v-if="search && searchType === 'ports'" class="search svg-hold" @click="$store.commit('PORT_SEARCH_TOGGLE')">
				<SearchIcon class="search-icon"></SearchIcon>
			</a>
            <a v-if="search && searchType === 'resources'" class="search svg-hold" @click="$store.commit('RESOURCE_SEARCH_TOGGLE')">
                <SearchIcon class="search-icon"></SearchIcon>
            </a>
			<a class="add svg-hold" v-if="add">
				<AddIcon class="add-icon"></AddIcon>
			</a>
			<a class="star svg-hold" v-if="star">
				<StarIcon class="star-icon star-icon-active"></StarIcon>
			</a>
            <a class="star svg-hold" v-if="starOutline">
                <StarIcon class="star-icon star-icon"></StarIcon>
            </a>
            <router-link :to="{ name: 'RequestsNew' }" class="pencil svg-hold" v-if="pencil">
                <PencilIcon class="pencil-icon"></PencilIcon>
            </router-link>
			<div class="notification-wrap svg-hold">
				<a v-if="$route.name == 'Notifications'" @click="$router.go(-1)">
					<NotificationIcon class="notification-icon"></NotificationIcon>
					<span v-if="$store.state.notifications" class="notification-circle"></span>
				</a>
				<router-link v-else :to="{ name: 'Notifications' }">
					<NotificationIcon class="notification-icon"></NotificationIcon>
					<span v-if="$store.state.notifications" class="notification-circle"></span>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import MenuIcon from '@/assets/menu.svg';
import SearchIcon from '@/assets/search.svg';
import AddIcon from '@/assets/add.svg';
import BackIcon from '@/assets/arrow-left.svg';
import StarIcon from '@/assets/star.svg';
import PencilIcon from '@/assets/pencil.svg';
import NotificationIcon from '@/assets/notification.svg';
export default {
	name: 'HeaderNavBar',
	props: {
		label: String,
        route: String,
		search: Boolean,
        searchType: String,
		add: Boolean,
		star: Boolean,
		pencil: Boolean,
        starOutline: Boolean,
		back: Boolean
	},
	methods: {
        toggleMenu () {
            this.$store.commit('MENU_TOGGLE');
        }
    },
	components: {
		MenuIcon,
		SearchIcon,
		StarIcon,
		NotificationIcon,
		AddIcon,
		BackIcon,
		PencilIcon
	}
};
</script>

<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.header-nav-bar {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}
	.nav-bar-right, .nav-bar-left {
		display: flex;
		align-items: center;
		svg {
			display: block;
		}
	}
	.svg-hold {
		display: flex;
		margin-left: 18px;
	}
	.search-icon {
		width: 27px;
		g {
			stroke: $white;
		}
	}
	.add-icon {
		width: 30px;
		g {
			stroke: $white;
		}
	}
	.pencil-icon {
		width: 27px;
		path {
			stroke: $white;
		}
	}
	.star-icon {
		width: 32px;
		path {
			stroke: $white;
		}
		&.star-icon-active {
			path {
				fill: $white;
			}
		}
	}
	.menu-link {
		display: flex;
	}
	.menu-icon {
		width: 28px;
		g {
			stroke: $white;
		}
	}
	.notification-wrap {
		position: relative;
	}
	.notification-icon {
		width: 28px;
		g {
			stroke: $white;
		}
	}
	.notification-circle {
		position: absolute;
		top: -2px;
		right: 2px;
		width: 11px;
		height: 11px;
		border-radius: 100%;
		background: $red_base;
	}
</style>
